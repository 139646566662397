<template>
  <div class="container-fluid ml-7">
    <div>
      <TablePart />
    </div>
  </div>
</template>

<script>
import TablePart from "@/components/part/TablePart.vue";
export default {
    components:{TablePart}
};
</script>

<style></style>
