<template>
  <div class="row">
    <div class="col-md-11 col-xl-12">
      <div class="card card-custom mb-3">
        <div class="card-header" style="border-color: var(--bordercolorform)">
          <div class="card-title">
            <h3 class="card-label cardLabelTiket">Logistik</h3>
          </div>
          <div class="card-toolbar">
            <b-button
              variant="danger"
              @click.prevent.stop="showModalAdd = true"
            >
              <b-icon-plus></b-icon-plus> Add Logistik
            </b-button>
          </div>
        </div>

        <div
          class="row no-gutters"
          style="display: flex; justify-content: center !important"
        >
          <div style="width: 98%;margin-top:21px">
            <div id="chart" style="min-height: 300px;">
              <DxDataGrid
                :ref="dataGridRefKey"
                :data-source="dataSource"
                :columns="columns"
                key-expr="id"
                :show-borders="true"
                :word-wrap-enabled="false"
                :column-auto-width="false"
                :remote-operations="true"
                :column-hiding-enabled="false"
              >
                <DxSearchPanel :visible="true" />
                <DxFilterRow :visible="false" />
                <DxColumn
                  data-field="id_part"
                  caption="Id Part"
                  :allow-filtering="true"
                />
                <DxColumn
                  data-field="name"
                  caption="Name Part"
                  :allow-filtering="true"
                />
                <DxColumn
                  data-field="description"
                  caption="Description"
                  :allow-filtering="true"
                />
                <DxColumn
                  data-field="quantity_stock"
                  caption="Quantity stock"
                  :allow-filtering="true"
                />
                <DxColumn
                  data-field="reorder_level"
                  caption="Reorder Level"
                  :allow-filtering="true"
                />
                <DxColumn
                  data-field="quantity_in_reorder"
                  caption="Quantity in reorder"
                  :allow-filtering="true"
                />
                <DxColumn
                  data-field="reorder_time_in_day"
                  caption="Reorder time in day"
                  :allow-filtering="true"
                />
                <DxColumn
                  data-field="warehouse.name"
                  caption="Warehouse Name"
                  :allow-filtering="true"
                />
                <DxColumn
                  sort-order="desc"
                  data-field="created_at"
                  caption="Created At"
                />
                <DxColumn :width="100" type="buttons" caption="Aksi">
                  <DxButton icon="fa fa-trash" hint="Hapus" :onClick="hapus" />
                  <DxButton icon="fa fa-edit" hint="Edit" :onClick="iconEdit" />
                </DxColumn>
                <DxPaging :page-size="10" />
                <DxPager
                  :visible="true"
                  :show-page-size-selector="showPageSizeSelector"
                  :allowed-page-sizes="pageSizes"
                />
              </DxDataGrid>
            </div>
          </div>
        </div>
        <b-modal
          v-model="showModalAdd"
          hide-footer
          centered
          scrollable
          size="lg"
          id="add-new-modal"
        >
          <template #modal-header="{ close }" class="text-center">
            <h3>Tambah</h3>
          </template>
          <b-form @submit.stop.prevent="onSubmit">
            <b-form-group
              id="example-input-group-1"
              label-for="example-input-1"
            >
              <template v-slot:label>
                ID Part<span class="text-danger">*</span>
              </template>
              <b-form-input
                id="example-input-1"
                name="example-input-1"
                placeholder="Code"
                v-model="$v.form.id_part.$model"
                :state="validateState('id_part')"
                aria-describedby="input-1-live-feedback"
              ></b-form-input>

              <b-form-invalid-feedback id="input-1-live-feedback"
                >Id Part harus diisi.
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group id="example-input-group-3" label-for="input-name">
              <template v-slot:label>
                Name<span class="text-danger">*</span>
              </template>
              <b-form-input
                id="input-name"
                name="input-name"
                placeholder="Nama"
                v-model="$v.form.name.$model"
                :state="validateState('name')"
                aria-describedby="input-1-live-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="input-1-live-feedback"
                >Nama harus diisi.
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="example-input-group-3" label-for="input-name">
              <template v-slot:label>
                Quantity in reorder<span class="text-danger">*</span>
              </template>
              <b-form-input
                id="input-name"
                name="input-name"
                type="number"
                placeholder="Quantity in reorder"
                v-model="$v.form.quantity_in_reorder.$model"
                :state="validateState('quantity_in_reorder')"
                aria-describedby="input-1-live-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="input-1-live-feedback"
                >Quantity In Reorder harus diisi.
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="example-input-group-3" label-for="input-name">
              <template v-slot:label>
                Quantity Stock<span class="text-danger">*</span>
              </template>
              <b-form-input
                id="input-name"
                name="input-name"
                type="number"
                placeholder="Quantity Stock"
                v-model="$v.form.quantity_stock.$model"
                :state="validateState('quantity_stock')"
                aria-describedby="input-1-live-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="input-1-live-feedback"
                >Quantity Stock harus diisi.
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="example-input-group-3" label-for="input-name">
              <template v-slot:label>
                Reorder Level<span class="text-danger">*</span>
              </template>
              <b-form-input
                id="input-name"
                name="input-name"
                placeholder="reorder_level"
                type="number"
                v-model="$v.form.reorder_level.$model"
                :state="validateState('reorder_level')"
                aria-describedby="input-1-live-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="input-1-live-feedback"
                >Reorder Level harus diisi.
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="example-input-group-3" label-for="input-name">
              <template v-slot:label>
                Reorder Timein Day<span class="text-danger">*</span>
              </template>
              <b-form-input
                id="input-name"
                name="input-name"
                placeholder="Reorder time in day"
                type="number"
                v-model="$v.form.reorder_time_in_day.$model"
                :state="validateState('reorder_time_in_day')"
                aria-describedby="input-1-live-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="input-1-live-feedback"
                >Reorder harus diisi.
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="example-input-group-3" label-for="input-name">
              <template v-slot:label>
                Description<span class="text-danger">*</span>
              </template>
              <b-form-input
                id="input-name"
                name="input-name"
                placeholder="Description"
                v-model="$v.form.description.$model"
                :state="validateState('description')"
                aria-describedby="input-1-live-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="input-1-live-feedback"
                >Description harus diisi.
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="example-input-group-3" label-for="input-name">
              <template v-slot:label>
                Warehouse<span class="text-danger"></span>
              </template>
              <b-form-select
                id="select-provinsi"
                placeholder="Warehouse"
                v-model="$v.form.warehouse_id.$model"
                :options="warehouse"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >-- Pilih Salah Satu --</b-form-select-option
                  >
                </template>
              </b-form-select>
              <!-- <b-form-invalid-feedback id="input-1-live-feedback"
                >Warehouse harus diisi.
              </b-form-invalid-feedback> -->
            </b-form-group>
            <hr />
            <div class="d-flex justify-content-center">
              <button
                class="btn btn-cancel mr-8"
                type="button"
                @click="onCancel()"
              >
                Kembali
              </button>
              <button type="submit" class="btn btn-add">Simpan</button>
            </div>
          </b-form>
        </b-modal>

        <!--   Edit Existing User   -->
        <b-modal
          v-model="showModalEdit"
          hide-footer
          scrollable
          centered
          size="lg"
          id="edit-user-modal"
        >
          <template #modal-header="{ close }">
            <h3>Edit</h3>
          </template>
          <b-form @submit.stop.prevent="onSubmitEdit">
            <b-form-group
              id="example-input-group-1"
              label-for="example-input-1"
            >
              <template v-slot:label>
                ID Part<span class="text-danger">*</span>
              </template>
              <b-form-input
                id="example-input-1"
                name="example-input-1"
                placeholder="Code"
                v-model="$v.formEdit.id_part.$model"
                :state="validateStateFormEdit('id_part')"
                aria-describedby="input-1-live-feedback"
              ></b-form-input>

              <b-form-invalid-feedback id="input-1-live-feedback"
                >Id Part harus diisi.
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group id="example-input-group-3" label-for="input-name">
              <template v-slot:label>
                Name<span class="text-danger">*</span>
              </template>
              <b-form-input
                id="input-name"
                name="input-name"
                placeholder="Nama"
                v-model="$v.formEdit.name.$model"
                :state="validateStateFormEdit('name')"
                aria-describedby="input-1-live-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="input-1-live-feedback"
                >Nama harus diisi.
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="example-input-group-3" label-for="input-name">
              <template v-slot:label>
                Quantity in reorder<span class="text-danger">*</span>
              </template>
              <b-form-input
                id="input-name"
                name="input-name"
                type="number"
                placeholder="Quantity in reorder"
                v-model="$v.formEdit.quantity_in_reorder.$model"
                :state="validateStateFormEdit('quantity_in_reorder')"
                aria-describedby="input-1-live-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="input-1-live-feedback"
                >Quantity In Reorder harus diisi.
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="example-input-group-3" label-for="input-name">
              <template v-slot:label>
                Quantity Stock<span class="text-danger">*</span>
              </template>
              <b-form-input
                id="input-name"
                name="input-name"
                type="number"
                placeholder="Quantity Stock"
                v-model="$v.formEdit.quantity_stock.$model"
                :state="validateStateFormEdit('quantity_stock')"
                aria-describedby="input-1-live-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="input-1-live-feedback"
                >Quantity Stock harus diisi.
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="example-input-group-3" label-for="input-name">
              <template v-slot:label>
                Reorder Level<span class="text-danger">*</span>
              </template>
              <b-form-input
                id="input-name"
                name="input-name"
                placeholder="reorder_level"
                type="number"
                v-model="$v.formEdit.reorder_level.$model"
                :state="validateStateFormEdit('reorder_level')"
                aria-describedby="input-1-live-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="input-1-live-feedback"
                >Reorder Level harus diisi.
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="example-input-group-3" label-for="input-name">
              <template v-slot:label>
                Reorder Timein Day<span class="text-danger">*</span>
              </template>
              <b-form-input
                id="input-name"
                name="input-name"
                placeholder="Reorder time in day"
                type="number"
                v-model="$v.formEdit.reorder_time_in_day.$model"
                :state="validateStateFormEdit('reorder_time_in_day')"
                aria-describedby="input-1-live-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="input-1-live-feedback"
                >Reorder harus diisi.
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="example-input-group-3" label-for="input-name">
              <template v-slot:label>
                Description<span class="text-danger">*</span>
              </template>
              <b-form-input
                id="input-name"
                name="input-name"
                placeholder="Description"
                v-model="$v.formEdit.description.$model"
                :state="validateStateFormEdit('description')"
                aria-describedby="input-1-live-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="input-1-live-feedback"
                >Description harus diisi.
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="example-input-group-3" label-for="input-name">
              <template v-slot:label>
                Warehouse<span class="text-danger">*</span>
              </template>
              <b-form-select
                id="select-provinsi"
                placeholder="Warehouse"
                v-model="$v.formEdit.warehouse_id.$model"
                :options="warehouse"
                :state="validateStateFormEdit('warehouse_id')"
                aria-describedby="input-role-live-feedback"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >-- Pilih Salah Satu --</b-form-select-option
                  >
                </template>
              </b-form-select>
              <b-form-invalid-feedback id="input-1-live-feedback"
                >Warehouse harus diisi.
              </b-form-invalid-feedback>
            </b-form-group>
            <hr />
            <div class="d-flex justify-content-center">
              <button
                class="btn btn-cancel mr-8"
                type="button"
                @click="$bvModal.hide('edit-user-modal')"
              >
                Kembali
              </button>
              <button type="submit" class="btn btn-add">Simpan</button>
            </div>
          </b-form>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import {
  DxButton,
  DxColumn,
  DxDataGrid,
  DxFilterRow,
  DxPager,
  DxPaging,
  DxSearchPanel
} from "devextreme-vue/data-grid";
import WebService from "../../core/services/api";
// import EventBus from "../../../core/services/common/EventBus";
import CustomStore from "devextreme/data/custom_store";
import { isNotEmpty } from "../../core/Apputil/Dx";

const dataGridRefKey = "my-data-grid";

const store = new CustomStore({
  key: "id",
  load: function(loadOptions) {
    let params = "?";
    [
      "skip",
      "take",
      "requireTotalCount",
      "requireGroupCount",
      "sort",
      // "filter",
      "totalSummary",
      "group",
      "groupSummary"
    ].forEach(function(i) {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {
        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
      }
    });
    params = params.slice(0, -1);

    // search by name if exists

    if (
      Array.isArray(loadOptions["filter"]) &&
      loadOptions["filter"].length > 0 &&
      loadOptions["filter"][0] === "name"
    ) {
      params += "&nama=" + loadOptions["filter"][2];
    }
    return WebService.getAlllogistik(params).then(res => {
      return {
        data: res.data.data,
        totalCount: res.data.totalCount,
        summary: res.data.summary,
        groupCount: res.data.groupCount
      };
    });
  }
});

export default {
  mixins: [validationMixin],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    DxButton,
    DxSearchPanel,
    DxColumn,
    DxDataGrid,
    DxFilterRow,
    DxPager,
    DxPaging
  },
  data() {
    return {
      form: {
        id_part: "",
        name: "",
        quantity_in_reorder: "",
        quantity_stock: "",
        reorder_level: "",
        reorder_time_in_day: "",
        warehouse_id: "",
        description: ""
      },
      formEdit: {
        id: null,
        id_part: "",
        name: "",
        quantity_in_reorder: "",
        quantity_stock: "",
        reorder_level: "",
        reorder_time_in_day: "",
        warehouse_id: "",
        description: ""
      },
      warehouse: [],
      searchID: "",
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      dataSource: store,
      dataGridRefKey,
      showPageSizeSelector: true,
      pageSizes: [5, 10, 20],
      columns: ["id", "name", "icon"],
      file1: null,
      file2: null,
      showModalAdd: false,
      showModalEdit: false,
      statuses: [
        {
          value: "true",
          text: "Aktif"
        },
        { value: "false", text: "Tidak Aktif" }
      ],
      infoModal: {
        id: "info-modal",
        title: "",
        content: ""
      },
      selected: null,
      optionsmodule: [
        { value: null, text: "Select One" },
        { value: "a", text: "Yes" },
        { value: "b", text: "No" },
        { value: "c", text: "--" },
        { value: "d", text: "--" }
      ],
      // array user
      itemsuser: [],
      roles: [],
      fieldsuser: [
        {
          key: "no",
          label: "No.",
          class: "text-center"
        },
        {
          key: "code",
          label: "Code",
          sortable: true,
          class: "text-center"
        },
        {
          key: "name",
          label: "Nama Kota/Kab",
          sortable: true,
          class: "text-center"
        },
        { key: "actions", label: "Actions" }
      ]
    };
  },
  validations: {
    form: {
      id_part: {
        required
      },
      name: {
        required
      },
      quantity_in_reorder: {
        required
      },
      quantity_stock: {
        required
      },
      reorder_level: {
        required
      },
      reorder_time_in_day: {
        required
      },
      warehouse_id: {
        
      },
      description: {
        required
      }
    },
    formEdit: {
      id_part: {
        required
      },
      name: {
        required
      },
      quantity_in_reorder: {
        required
      },
      quantity_stock: {
        required
      },
      reorder_level: {
        required
      },
      reorder_time_in_day: {
        required
      },
      warehouse_id: {
        
      },
      description: {
        required
      }
    }
  },
  mounted() {
    // this.getDataTable();
    this.getWarehouse();
  },
  computed: {
    dataGrid: function() {
      return this.$refs[dataGridRefKey].instance;
    }
  },
  methods: {
    getWarehouse() {
      WebService.getallLogistikWarehouse().then(async res => {
        const warehouse = res.data.data;
        let selectedOptions;
        selectedOptions = [];
        warehouse.map(i => {
          return selectedOptions.push({
            text: i.name,
            value: i.id
          });
        });
        this.warehouse = selectedOptions;
        console.log(selectedOptions);
      });
    },
    getRoles() {
      WebService.getProvAll().then(async res => {
        const roles = res.data.data;
        let selectedOptions;
        selectedOptions = [];
        roles.map(i => {
          return selectedOptions.push({
            text: i.name,
            value: i.id
          });
        });
        this.roles = selectedOptions;
        console.log(selectedOptions);
      });
    },
    getDataTable() {
      this.dataGrid.refresh();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    validateStateFormEdit(name) {
      const { $dirty, $error } = this.$v.formEdit[name];
      return $dirty ? !$error : null;
    },
    getLabelCounter() {
      let from = (this.currentPage - 1) * this.perPage + 1;
      let dari =
        this.perPage > this.itemsuser.length
          ? this.currentPage * this.perPage -
            this.perPage +
            this.itemsuser.length
          : this.currentPage * this.perPage;
      return (
        "Shown results " +
        from +
        " - " +
        dari +
        " From " +
        this.totalRows +
        " Data"
      );
    },
    onCancel() {
      this.showModalAdd = false;
      this.form = {
        warehouse_id: ""
      };
      this.formEdit = {
        warehouse_id: ""
      };
      this.resetForm();
    },
    getNumberSequence(index) {
      return index + 1 + this.currentPage * this.perPage - this.perPage;
    },
    hapus(value) {
      const id = value.row.data.id;
      Swal.fire({
        title: "Konfirmasi",
        text: "Apakah Yakin Data Dihapus?.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "OK",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return WebService.deleteLogistik2(id)
            .then(data => {
              console.log(data);
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Data berhasil Dihapus",
                showConfirmButton: false,
                timer: 1500
              });
              this.resetForm();
              this.getDataTable();
            })
            .catch(error => {
              console.log(error);
              Swal.fire({
                title: "Kesalahan",
                text: "Data Gagal Dihapus",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    hideModal() {
      this.$refs["my-modaledit"].hide();
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      let data = {
        id_part: this.form.id_part,
        name: this.form.name,
        quantity_in_reorder: parseInt(this.form.quantity_in_reorder),
        quantity_stock: parseInt(this.form.quantity_stock),
        reorder_level: parseInt(this.form.reorder_level),
        reorder_time_in_day: parseInt(this.form.reorder_time_in_day),
        warehouse_id: this.form.warehouse_id,
        description: this.form.description
      };

      Swal.fire({
        title: "Konfirmasi",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return WebService.addLogistict(data)
            .then(data => {
              console.log(data);
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Data berhasil ditambah",
                showConfirmButton: false,
                timer: 1500
              });
              this.$v.form.$reset();
              this.resetForm();
              this.getDataTable();
            })
            .catch(error => {
              console.log(error);
              Swal.fire({
                title: "Kesalahan",
                text: "Data Gagal ditambahkan",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    onSubmitEdit() {
      this.$v.formEdit.$touch();
      if (this.$v.formEdit.$anyError) {
        return;
      }

      let id = this.formEdit.id;
      let data = {
        id_part: this.formEdit.id_part,
        name: this.formEdit.name,
        quantity_in_reorder: parseInt(this.formEdit.quantity_in_reorder),
        quantity_stock: parseInt(this.formEdit.quantity_stock),
        reorder_level: parseInt(this.formEdit.reorder_level),
        reorder_time_in_day: parseInt(this.formEdit.reorder_time_in_day),
        warehouse_id: this.formEdit.warehouse_id,
        description: this.formEdit.description
      };

      console.log(data);

      Swal.fire({
        title: "Konfirmasi",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return WebService.editLogistik2(data, id)
            .then(data => {
              Swal.fire({
                position: "center",
                icon: "success",
                title: data.success,
                showConfirmButton: false,
                timer: 1500
              });
              this.$v.formEdit.$reset();
              this.resetFormEdit();
              this.getDataTable();
            })
            .catch(error => {
              console.log(error);
              Swal.fire({
                title: "Kesalahan",
                text: "Gagal mengubah data",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    resetForm() {
      this.form = {
        id: null,
        code: "",
        name: "",
      };
      this.$v.form.$touch();
      this.$v.form.$reset();
      this.$nextTick(() => {
        this.$bvModal.hide("add-new-modal");
      });
    },
    resetFormEdit() {
      this.formEdit = {
        id: null,
        code: "",
        name: ""
      };

      this.$nextTick(() => {
        this.$bvModal.hide("edit-user-modal");
      });
    },
    iconEdit(e) {
      console.log(e.item);
      this.showModalEdit = true;
      const data = e.row.data;
      this.formEdit.id = data.id;
      this.formEdit.id_part = data.id_part;
      this.formEdit.name = data.name;
      this.formEdit.quantity_in_reorder = data.quantity_in_reorder;
      this.formEdit.quantity_stock = data.quantity_stock;
      this.formEdit.reorder_level = data.reorder_level;
      this.formEdit.reorder_time_in_day = data.reorder_time_in_day;
      this.formEdit.warehouse_id = data.warehouse_id;
      this.formEdit.description = data.description;
    }
  }
};
</script>

<style>
.btn-add {
  width: 144px;
  height: 44px;
  background: #c60b09;
  border-radius: 8px;
  color: #fffafa;
}
.btn-cancel {
  width: 144px;
  height: 44px;
  border: 1px solid #c60b09;
  box-sizing: border-box;
  border-radius: 8px;
  color: #c60b09;
}
.title-card-custom {
  font-weight: 600;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: 0.005em;
}
.table-custom {
  border-radius: 15px 15px 15px 15px !important;
}

.thead-block {
  text-align: center;
  background: #d1eefa;
  color: #360302;
  border: none;
}

.thead-block tr th:first-child {
  border-top-left-radius: 15px;
}

.thead-block tr th:last-child {
  border-top-right-radius: 15px;
}

.table-content {
  background: #fafcff;
  text-align: center;
  border-radius: 0 0 15px 15px;
}
</style>
